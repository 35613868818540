import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Logo from "../assets/img/logo.png";
import { getBaseApiURL, showToast } from "../utils/classHelper";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

export default function JoinInvite() {
  const searchParams = new URLSearchParams(window.location.search);
  const codeParameter = searchParams.get("code");

  const [code, setCode] = useState(codeParameter);
  const [isClickJoinButtonDisabled, setIsClickJoinButtonDisabled] = useState(false);
  const [name, setName] = useState();

  const [isSuccess, setIsSuccess] = useState(false);

  const clickedJoinButton = () => {

    setIsClickJoinButtonDisabled(true);

    console.log("name", name);
    console.log("code", code);
    let url = getBaseApiURL() + `/join-invite/${code}`;
    axios
      .put(url, {
        person_name: name,
      })
      .then((response) => {
        showToast("Successfully Joined");
        setIsSuccess(true);
        setIsClickJoinButtonDisabled(false);
      })
      .catch((error) => {
        setIsClickJoinButtonDisabled(false);
        if (error.response) {
          if (error.response.data.errors) {
            console.log(error.response.data.errors[0]);
            showToast(error.response.data.errors[0], "error");
          }
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Join Invite | Ready Group Go</title>
        <meta
          name="description"
          content="You can Join your class my entering the Code which you intructor has shared with you and your name."
        />
        <meta name="author" content="" />
        <meta property="og:title" content="Join Invite | Ready Group Go" />
        <meta
          property="og:description"
          content="You can Join your class my entering the Code which you intructor has shared with you and your name."
        />
        <meta property="og:url" content="https://readygroupgo.com/join" />
        <meta name="twitter:title" content="Join Invite | Ready Group Go" />
        <meta
          name="twitter:description"
          content="You can Join your class my entering the Code which you intructor has shared with you and your name."
        />
        <meta name="twitter:image" content="https://readygroupgo.com/join" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="join-page text-center">
        <a href="/">
          <img src={Logo} alt="" className="logo" />
        </a>

        <h3 className="mt-5">Make your own groups at readygroupgo.com</h3>
        <div className="box">
          {isSuccess ? (
            <div className="success-message">
              You have Successfully Joined the Class.
              <br />
              You may exit this page now or go to{" "}
              <a href="/" className="link">
                Homepage
              </a>
            </div>
          ) : (
            <>
              {codeParameter ? (
                ""
              ) : (
                <Form.Group className=" each-field">
                  <Form.Control
                    className="student-list"
                    value={code}
                    onInput={(e) => setCode(e.target.value)}
                    placeholder="Enter Code"
                  />
                </Form.Group>
              )}

              <Form.Group className="each-field">
                <Form.Control
                  className="student-list"
                  value={name}
                  onInput={(e) => setName(e.target.value)}
                  placeholder="Enter Name"
                />
              </Form.Group>

              <button
                className="btn-brighter-primary submit-button"
                onClick={clickedJoinButton}
                disabled={isClickJoinButtonDisabled}
              >
                Join Class/Group
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
