import { createSlice } from "@reduxjs/toolkit";
import { loadDefaultNames } from "../../utils/classHelper";

export const classSlice = createSlice({
  name: "group",
  initialState: {
    students: loadDefaultNames(),
    groups: [],
    specialGroups: [],
    selectedClassId: 0,
    selectedClassName: "",
    selectedClassShareableId: "",
    selectedGroupSize: 2,
    selectedAttribute: "random",
    selectedClassAttrValues: {
      attr1: "Attr 1",
      attr2: "Attr 2",
      attr3: "Attr 3",
    },
  },

  reducers: {
    setNames: (state, action) => {
      state.students = action.payload;
    },
    saveGroups: (state, action) => {
      state.groups = action.payload;
    },

    setInsideGroupSelectedClassId: (state, action) => {
      state.selectedClassId = action.payload;
    },

    setInsideGroupSelectedGroupSize: (state, action) => {
      state.selectedGroupSize = action.payload;
    },
    setInsideGroupSelectedAttribute: (state, action) => {
      state.selectedAttribute = action.payload;
    },
    setSpecialGroups: (state, action) => {
      state.specialGroups = action.payload;
    },
    setSelectedClassAttrValues: (state, action) => {
      state.selectedClassAttrValues = action.payload;
    },
    setIinsideGroupSelectedClassName: (state, action) => {
      state.selectedClassName = action.payload;
    },
    setIinsideGroupSelectedClassShareableId: (state, action) => {
      state.selectedClassShareableId = action.payload;
    },
  },
});

export const {
  setNames,
  saveGroups,
  setInsideGroupSelectedClassId,
  setInsideGroupSelectedGroupSize,
  setInsideGroupSelectedAttribute,
  setSpecialGroups,
  setSelectedClassAttrValues,
  setIinsideGroupSelectedClassName,
  setIinsideGroupSelectedClassShareableId,
} = classSlice.actions;
export default classSlice.reducer;
