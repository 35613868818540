import logo from "./logo.svg";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import TopNavbar from "./app/templates/TopNavbar";
import { isTokenPresent } from "./utils/classHelper";
import { useDispatch } from "react-redux";
import { setloggedIn } from "./store/slices/loggedInSlice";
import { setName } from "./store/slices/userSlice";
import Generator from "./app/Generator";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ShareableGroup from "./app/ShareableGroup";
import NotFound from "./app/NotFound";
import JoinInvite from "./app/JoinInvite";
import { selectSidebarSubOption } from "./store/slices/sidebarOptionSlice";

function App() {
  const dispatch = useDispatch();

  const website_type = process.env.REACT_APP_WEBSITE_TYPE;

  if (isTokenPresent()) {
    dispatch(setloggedIn(true));

    // //console.log('getName',localStorage.getItem('name'));
    dispatch(setName(localStorage.getItem("name")));

  } else {
    dispatch(selectSidebarSubOption("text"));
  }

  return (
    <BrowserRouter>
      <div className="App">
        <ToastContainer />

        {website_type == "main" ? (
          <Routes>
            <Route path="/" element={<Generator></Generator>} />
            <Route
              path="/group/:shareableID"
              element={<ShareableGroup></ShareableGroup>}
            />
            {/* <Route path="/join" element={<JoinInvite></JoinInvite>} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<JoinInvite></JoinInvite>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
