import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    loginShow: false,
    loginText: "Login To Get Started",
    registerShow: false,
    saveClassShow: false,
    addClassShow: false,
    deleteStudentsShow: false,
    editClassAttributesShow: false,
    shareableGroupShow: false,
    deleteClassShow: false,
    renameClassShow: false,
    inviteStudentsShow: false,
    feedbackShow: false,
  },

  reducers: {
    setLoginModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.loginShow = action.payload;
    },

    setLoginModalText: (state, action) => {
      state.loginText = action.payload;
    },

    setRegisterModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.registerShow = action.payload;
    },

    setSaveClassModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.saveClassShow = action.payload;
    },

    setAddClassModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.addClassShow = action.payload;
    },
    setRenameClassModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.renameClassShow = action.payload;
    },
    setDeleteClassModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.deleteClassShow = action.payload;
    },
    setDeleteStudentsModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.deleteStudentsShow = action.payload;
    },
    setEditClassAttributesShow: (state, action) => {
      //console.log('action.payload',action.payload);
      state.editClassAttributesShow = action.payload;
    },
    setShareableGroupShow: (state, action) => {
      //console.log('action.payload',action.payload);
      state.shareableGroupShow = action.payload;
    },
    setInviteStudentsModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.inviteStudentsShow = action.payload;
    },
    setFeedbackModal: (state, action) => {
      //console.log('action.payload',action.payload);
      state.feedbackShow = action.payload;
    },
  },
});

export const {
  setLoginModal,
  setRegisterModal,
  setSaveClassModal,
  setLoginModalText,
  setAddClassModal,
  setDeleteStudentsModal,
  setDeleteStudentsModalText,
  setEditClassAttributesShow,
  setShareableGroupShow,
  setDeleteClassModal,
  setRenameClassModal,
  setInviteStudentsModal,
  setFeedbackModal
} = modalSlice.actions;
export default modalSlice.reducer;
